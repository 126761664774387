<template>
<div class="examin" v-loading="pageLoading">
      <img class="pass" :src="passImg" v-if="isPass"/>
      <img class="noPass" :src="noPassImg" v-else/>
      <div class="examinText">{{isPass ? '恭喜您，成功通过考试！' : '考试未通过，继续加油！'}}</div>
      <div class="btn">
          <!-- <p  @click="toExaminList" v-if="isPass" class="certBtn">返回考试列表</p> -->
          <p @click="toDetail" class="detailBtn">查看考试详情</p>
          <p @click="toHome" class="detailBtn">返回首页</p>
      </div>
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      id:'',
     isPass:true,
      passImg:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.png',
      noPassImg:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.png',
      pageLoading: false,
     
    };
  },
  async mounted() {
    this.id = this.$route.query.id
    this.isPass = this.$route.query.isPass==1 ? true : false
   
  },
  destroyed() {
   
  },
  computed: {
   
  },
  methods: {
    toExaminList(){
     
      this.$router.push({path:"./certSave?examinId="+this.$route.query.examinId})
    },
    toDetail(){
      this.$router.push({path:"./examinResultDetail?id="+this.id+"&examinId="+this.$route.query.examinId})
    },
    toHome(){
      this.$router.push({path:"./index"})
    },
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`);
    },
    
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
  min-height: calc(100vh - 230px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  width:1200px;
  background: #fff;
  margin: 20px auto;
  text-align: center;
  .pass{
    width: 360px;
  }
  .noPass{
    width: 260px;
  }
  .examinText{
    font-size: 20px;
    color: #333333;
  }
  .btn{
    padding-top: 80px;
    p{
      display:inline-block;
      margin: 0 15px;
      width: 164px;
      height: 36px;
      line-height: 36px;
      border-radius: 6px;
      cursor: pointer;
    }
    .certBtn{
        color: #fff;
        background: #A40001;
    }
    .detailBtn{
      background: #F7F7F7;
      color: #A40001;
    }
  }
}
</style>

<style lang="scss">
</style>
